.bg-container {
  position: absolute;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  inset: 0;

  background-image: url('../../assets/mint/background.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.bg-inner-container {
  width: 521px;
  margin: 100px auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.logo {
  width: 100%;
  max-width: 337px;
}

.alert {
  width: 100%;
  border-radius: 20px;
  text-align: center;
  padding: 16px 0px;
  background: #33007E;
  color: white;
  font-size: 18px;
  margin: 16px auto;
}

.wallet-container {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loading-container {
  position: absolute;
  inset: 0;
  border-radius: 20px;
  background: black;
  opacity: 0.7;
  z-index: 10;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.wallet {
  width: 100%;
  border-radius: 20px;
  border: solid 2px black;
  background: #B1E441;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.wallet-address {
  width: 100%;
  border-bottom: 1px solid black;
  padding: 16px 0px;
}

.wallet-inner-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.wallet-supply {
  width: 50%;
  border-right: 1px solid black;
  padding: 16px 0px;
}

.wallet-status {
  width: 50%;
  padding: 16px 0px;
}

.mint {
  width: 100%;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #B1E441;
  margin: 16px auto 0px auto;
}

.mint-img-container {
  width: 100%;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  background: #BA7DEA;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.mint-img-container img {
  margin: 16px auto;
  max-width: 169px;
}

.total-price {
  width: 100%;
  border-bottom: 1px solid black;
  padding: 16px 0px;
}

.count-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.connect-btn {
  width: 100%;
  padding: 16px 0px;
  text-align: center;
  background: black;
  color: white;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  cursor: pointer;
}

.count-minus {
  width: 70px;
  border-right: 1px solid black;
  padding: 16px 0px;
  cursor: pointer;
  user-select: none;
}

.count-value {
  border-right: 1px solid black;
  padding: 16px 0px;
  flex: 1 1 0%;
}

.count-plus {
  width: 70px;
  padding: 16px 0px;
  cursor: pointer;
  user-select: none;
}

.mint-btn {
  width: 110px;
  padding: 16px 0px;
  background: black;
  border-bottom-right-radius: 20px;
  color: white;
  font-weight: bolder;
  cursor: pointer;
  user-select: none;
}

.text-svg {
  width: 100%;
  margin: 16px auto;
}

.text-svg img {
  width: 100%;
}

.discord-svg {
  width: 100%;
  margin: 16px auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.discord-svg img {
  max-width: 342px;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
  cursor: pointer;
}

.discord-svg img:hover {
  transform: scale(1.1);
}

@media (max-width: 650px) {
  .bg-inner-container {
    width: 95%;
  }
  .discord-svg img:hover {
    transform: scale(1);
  }
}